exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-he-js": () => import("./../../../src/pages/about/index.he.js" /* webpackChunkName: "component---src-pages-about-index-he-js" */),
  "component---src-pages-articles-find-invest-index-en-js": () => import("./../../../src/pages/articles/find-invest/index.en.js" /* webpackChunkName: "component---src-pages-articles-find-invest-index-en-js" */),
  "component---src-pages-articles-find-invest-index-he-js": () => import("./../../../src/pages/articles/find-invest/index.he.js" /* webpackChunkName: "component---src-pages-articles-find-invest-index-he-js" */),
  "component---src-pages-articles-idea-to-product-index-en-js": () => import("./../../../src/pages/articles/idea-to-product/index.en.js" /* webpackChunkName: "component---src-pages-articles-idea-to-product-index-en-js" */),
  "component---src-pages-articles-idea-to-product-index-he-js": () => import("./../../../src/pages/articles/idea-to-product/index.he.js" /* webpackChunkName: "component---src-pages-articles-idea-to-product-index-he-js" */),
  "component---src-pages-articles-mass-prod-index-en-js": () => import("./../../../src/pages/articles/mass-prod/index.en.js" /* webpackChunkName: "component---src-pages-articles-mass-prod-index-en-js" */),
  "component---src-pages-articles-mass-prod-index-he-js": () => import("./../../../src/pages/articles/mass-prod/index.he.js" /* webpackChunkName: "component---src-pages-articles-mass-prod-index-he-js" */),
  "component---src-pages-articles-patent-reg-index-en-js": () => import("./../../../src/pages/articles/patent-reg/index.en.js" /* webpackChunkName: "component---src-pages-articles-patent-reg-index-en-js" */),
  "component---src-pages-articles-patent-reg-index-he-js": () => import("./../../../src/pages/articles/patent-reg/index.he.js" /* webpackChunkName: "component---src-pages-articles-patent-reg-index-he-js" */),
  "component---src-pages-articles-prototype-dev-index-en-js": () => import("./../../../src/pages/articles/prototype-dev/index.en.js" /* webpackChunkName: "component---src-pages-articles-prototype-dev-index-en-js" */),
  "component---src-pages-articles-prototype-dev-index-he-js": () => import("./../../../src/pages/articles/prototype-dev/index.he.js" /* webpackChunkName: "component---src-pages-articles-prototype-dev-index-he-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-he-js": () => import("./../../../src/pages/contact/index.he.js" /* webpackChunkName: "component---src-pages-contact-index-he-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-he-js": () => import("./../../../src/pages/index.he.js" /* webpackChunkName: "component---src-pages-index-he-js" */),
  "component---src-pages-projects-index-en-js": () => import("./../../../src/pages/projects/index.en.js" /* webpackChunkName: "component---src-pages-projects-index-en-js" */),
  "component---src-pages-projects-index-he-js": () => import("./../../../src/pages/projects/index.he.js" /* webpackChunkName: "component---src-pages-projects-index-he-js" */),
  "component---src-pages-recommends-index-en-js": () => import("./../../../src/pages/recommends/index.en.js" /* webpackChunkName: "component---src-pages-recommends-index-en-js" */),
  "component---src-pages-recommends-index-he-js": () => import("./../../../src/pages/recommends/index.he.js" /* webpackChunkName: "component---src-pages-recommends-index-he-js" */),
  "component---src-pages-workflow-index-en-js": () => import("./../../../src/pages/workflow/index.en.js" /* webpackChunkName: "component---src-pages-workflow-index-en-js" */),
  "component---src-pages-workflow-index-he-js": () => import("./../../../src/pages/workflow/index.he.js" /* webpackChunkName: "component---src-pages-workflow-index-he-js" */)
}

